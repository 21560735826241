import axios from "axios";
import React, { Component } from "react";

class SalarySlip extends Component{
    constructor(props){
        super(props);
        this.state={
            salarySlips:[],
        }
    }

    componentDidMount=async()=>{
        const searchParams = new URLSearchParams(window.location.search);
        const myParamValue = searchParams.get('faculty_id');

        if(myParamValue){
            this.getSingleFacultyData(myParamValue);
        }
        else{
            this.getAllFacultyData();
        }
    }

    convertJsonToCsv=async(jsonData)=> {
        const csvContent = [];
        
        // Extract headers
        const headers = Object.keys(jsonData[0]);
        csvContent.push(headers.join(','));
      
        // Extract data
        jsonData.forEach(row => {
          const values = headers.map(header => row[header]);
          csvContent.push(values.join(','));
        });
      
        return csvContent.join('\n');
      }

    
      downloadCsv=async()=> {
        const data = await this.convertJsonToCsv(this.state.salarySlips);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        
        // Release the object URL
        URL.revokeObjectURL(url);
      }

    getSingleFacultyData=async(myParamValue)=>{
        try{
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/getSalarySlipsIndividual`,{id:myParamValue},{
                headers: { Authorization: localStorage.getItem('token')}
            });
            if (data){
                this.setState({salarySlips:data.data})
            } 
        }
        catch{
            alert('Something Went Wrong!');
        }
    }

    getAllFacultyData = async()=>{
        try{
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/getSalarySlips`,{},{
                headers: { Authorization: localStorage.getItem('token')}
            });
            if (data){
                this.setState({salarySlips:data.data})
            } 
        }
        catch{
            alert('Something Went Wrong!');
        }
    }

    render(){
        const {salarySlips} = this.state;

        let slips = [];

        salarySlips.forEach(e=>{
            slips.push(
                <div className="container mt-3 text-class">
                <h5 className="text-center"><strong>Vasantdada Patil Pratishthan's College of Engineering and Visual Arts</strong></h5>
                <h6 className="text-center">Eastern Express Highway Near Everad Nagar, Sion-Chunabhatti</h6>
                <table className="table table-sm w-100 text-class">
                    <tr>
                        <td>
                            <strong>PaySlip :</strong> {e.pay_slip_id}
                        </td>
                        <td>
                            <strong>PaySlip For the Month:</strong> {e.pay_slip_month}
                        </td>
                        <td>
                            <strong>Branch:</strong> {e.branch}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>EMP Code :</strong> {e.faculty_clg_id}
                        </td>
                        <td>
                            <strong>Name:</strong> {e.name}
                        </td>
                        <td>
                            <strong>Grade:</strong> {e.grade}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Department :</strong> {e.department}
                        </td>
                        <td>
                            <strong>Designation:</strong> {e.designation}
                        </td>
                        <td>
                            <strong>Division:</strong> {e.division}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <strong>PF No:</strong> {e.pfno}
                        </td>
                        
                        <td>
                            <strong>Emp PAN No.:</strong> {e.panno}
                        </td>
                    </tr>
                </table>
                <hr />
                <table className="table table-sm w-100 text-class">
                    <tr>
                        <td>
                            <strong>Days Paid :</strong> {e.days_paid}
                        </td>
                        <td>
                            <strong>days Present:</strong> {e.days_present}
                        </td>
                        <td>
                            <strong>W.Off/pd.Off:</strong> {e.w_off}/{e.pd_off}
                        </td>
                        <td>
                            <strong>LWP/Absent :</strong>{e.lwp}/{e.absent}
                        </td>
                    </tr>
                </table>
                <hr />

                <table className="table table-sm w-100 text-class ex">
                    <tr className="text-center">
                        <th style={{border:'1px solid'}}>Earnings & Reimbursements</th>
                        <th style={{border:'1px solid'}}>Deductions & Recoveries</th>
                    </tr>
                    <tr>
                        <td style={{border:'1px solid'}}>
                            <table className="table table-sm w-100 ">
                                <tr>
                                    <td>Earned Basic</td>
                                    <td className="text-end">{e.basic_earn}</td>
                                </tr>
                                <tr>
                                    <td>DA</td>
                                    <td className="text-end">{e.da}</td>
                                </tr>
                                <tr>
                                    <td>HRA</td>
                                    <td className="text-end">{e.hra}</td>
                                </tr>
                                <tr>
                                    <td>TA</td>
                                    <td className="text-end">{e.ta}</td>
                                </tr>
                                <tr>
                                    <td>Other Allowence</td>
                                    <td className="text-end">{e.other_allow}</td>
                                </tr>
                                <tr>
                                    <td>AGP</td>
                                    <td className="text-end">{e.agp}</td>
                                </tr>
                                <tr>
                                    <td>SPL_Allow</td>
                                    <td className="text-end">{e.spl_allow}</td>
                                </tr>
                            </table>
                        </td>
                        <td style={{border:'1px solid'}}>
                            <table className="table table-sm w-100 ">
                                <tr>
                                    <td>Prov. Fund</td>
                                    <td className="text-end">{e.pf}</td>
                                </tr>
                                <tr>
                                    <td>P. Tax</td>
                                    <td className="text-end">{e.ptax}</td>
                                </tr>
                                <tr>
                                    <td>Income Tax</td>
                                    <td className="text-end">{e.income_tax}</td>
                                </tr>
                                <tr>
                                    <td>Loan Installments</td>
                                    <td className="text-end">{e.loan_installment}</td>
                                </tr>
                                <tr>
                                    <td>Other Deductions</td>
                                    <td className="text-end">{e.other_deduction}</td>
                                </tr>
                               
                            </table>
                        </td>
                       
                       
                    </tr>
                    <tr>
                        <td className="text-end" style={{border:'1px solid'}}>Total Earnings: {e.total_earning}</td>
                        <td className="text-end" style={{border:'1px solid'}}>Total Deduction: {e.total_deduction}<br/> Net Pay: {e.net_pay}</td>
                    </tr>
                </table>
                <span>Bank A/C No.: {e.bank_account_no}</span>
                
            </div>
            )
            
        })


        return(
            <>
            <div className="container">
                <div className="btns d-flex w-100 justify-content-between">
                    <button className="btn btn-primary mt-3" onClick={(e)=>this.downloadCsv(salarySlips)}>Download CSV</button>
                    <button className="btn btn-primary mt-3">Print</button>
                </div>
                
            </div>
            {slips}
            </>
        )
    }
}

export default SalarySlip;