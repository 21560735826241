import axios from "axios";
import React, { Component } from "react";

class PerForm extends Component{
    constructor(props){
        super(props)
        this.state={
            da:0,
            hra:0,
            pf:0,
            ta:0,
            ptax:0

        }
    }

    componentDidMount=async()=>{
        await this.fetchAllPercenage();
        await this.frtchAllFixedComponents();
    }

    fetchAllPercenage=async()=>{
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllPercenatge`,{
            headers: { Authorization: localStorage.getItem('token')}
        });
        if (data.msg) console.log(data.msg);
        else {
            let a = data.data;
            a.forEach(element => {
                if(element.name=='DA'){
                    this.setState({da:element.percentage})
                }
                else if(element.name=='HRA'){
                    this.setState({hra:element.percentage})
                }
                else{
                    this.setState({pf:element.percentage})
                }
            });
        }
     
    }

    frtchAllFixedComponents=async()=>{
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/frtchAllFixedComponents`,{
            headers: { Authorization: localStorage.getItem('token')}
        });
        if (data.msg) console.log(data.msg);
        else {
            let a = data.data;
            a.forEach(element => {
                if(element.name=='TA'){
                    this.setState({ta:element.amount})
                }
                else{
                    this.setState({ptax:element.amount})
                }
            });
        }
     
    }

    handleInputChange = async(e) => {
        const { name, value } = e.target;
    
        await this.setState((prevState) => ({
            [name]: value,
        }));
    }

    handleSaveInformationCllick = async() =>{
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/updatePercentage`,{
            da:this.state.da,
            hra:this.state.hra,
            pf:this.state.pf
        },{
            headers: { Authorization: localStorage.getItem('token')}
        });

        if(data.msg){
            alert(data.msg)
        }
    }

    handleSaveFixedInformationCllick = async() =>{
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/updateFixedComponents`,{
            ta:this.state.ta,
            ptax:this.state.ptax
        },{
            headers: { Authorization: localStorage.getItem('token')}
        });

        if(data.msg){
            alert(data.msg)
        }
    }

    render(){
      const {da,hra,pf,ta,ptax} = this.state;

        return(
            <div className="container mt-3">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title mb-3">Percentage Mapping</h5>
                        <hr />

                        <button className="btn btn-primary mt-3" onClick={(e)=>this.handleSaveInformationCllick()}>
                            Save Informations
                        </button>

                        <div className="row mt-3">
                          
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="da" class="form-control" id="floatingInput" value={da} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">D.A.</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="hra" class="form-control" id="floatingInput" value={hra} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">H.R.A.</label>
                                </div>
                            </div>


                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="pf" class="form-control" id="floatingInput" value={pf} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Prov. Fund</label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>  
                <div className="card mt-3">
                <div class="card-body">
                        <h5 class="card-title mb-3">Fixed Component Mapping</h5>
                        <hr />

                        <button className="btn btn-primary mt-3" onClick={(e)=>{this.handleSaveFixedInformationCllick()}}>
                            Save Informations
                        </button>

                        <div className="row mt-3">
                          
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="ta" class="form-control" id="floatingInput"
                                    value={ta} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">T.A.</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="ptax" class="form-control" id="floatingInput" value={ptax} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">P. Tax</label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>         
            </div>               
        )
    }
}

export default PerForm;