import React, { Component } from 'react';

class RefundForm extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    handleInputChange = (e) => {
        const { onAmountChange } = this.props;
        onAmountChange(e);
    };
    
    render(){
        return(
            <>
                 <div className="col-lg-5 col-md-5 col-sm-12 mt-3">
                    <div className="card">
                        <h5 className="card-title p-2">Refund Details Details</h5>
                        <hr />
                        <div className="card-body">
                            <div className="form-floating mb-3">
                                <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                name="amount"
                                placeholder='amount'
                                onChange={this.handleInputChange}
                                />
                                <label htmlFor="floatingInput">Amount</label>
                        </div>

                        <div className="form-floating mb-3">
                                <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                name="remark"
                                placeholder='remark'
                                onChange={this.handleInputChange}
                                />
                                <label htmlFor="floatingInput">Remark</label>
                        </div>

                        <div className="form-floating mb-3">
                                <input
                                type="date"
                                className="form-control"
                                id="floatingInput"
                                name="refund_date"
                                placeholder='refund_date'
                                onChange={this.handleInputChange}
                                />
                                <label htmlFor="floatingInput">Refund Date</label>
                        </div>

                        <div className="d-flex justify-content-between">
                            <button className="btn btn-primary" onClick={()=>this.props.handleAllowClick()}>Add</button>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default RefundForm;