import React, { Component } from 'react';

class Navbar extends Component {

  componentDidMount=async()=>{
    // console.clear();

  }

  logout = () =>{
    localStorage.clear();
    window.location.reload();
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src="logo.jpg"
              alt="Logo"
              width="30"
              height="24"
              className="d-inline-block align-text-top mx-2"
            />
            Academate
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{width:'100%',justifyContent:'end'}}>
              {
                localStorage.getItem('role')=='1' &&
                <>
                 <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/dashboard">
                  Dashboard
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Expense
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/SalaryForm">Faculty Salary Structure</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/SalarySlipMenu">Generate Salary Slip</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/salaryPercetageComponets">Salary Component Mapping</a></li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/CollectFee">
                  Collect Fee
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/Refund">
                 Refund
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/receipts">
                  Receipts
                </a>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Reports
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/report">Daily Report</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/BalReport">Balance Report</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/StudentsWiseReport">Student Wise Reports</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/RefundReport">Refund Report</a></li>
                </ul>
              </li>
             
              <li class="nav-item dropdown">
                <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Master
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/feeheadform">Add Fee Heads</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/mapfeeheads">Map Fee Structure</a></li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Bank Statements
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="/BankStatement">Generate Bank Statement</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="/StatementPrint">Print Statement</a></li>
                </ul>
              </li>
                </>
              }
             

              <li className="nav-item">
                <a className="nav-link active" onClick={()=>this.logout()} aria-current="page" href="#">
                  Logout
                </a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
