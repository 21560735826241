import axios from "axios";
import React, { Component } from "react";

class GenerateSalarySlip extends Component{
    constructor(props){
        super(props);
        this.state={
            facultyList:[],
            formated_date:'',
            selected_faculty:'',
        }
    }

    componentDidMount=async()=>{
        const date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);

        const formattedDate = date.toLocaleDateString('en-GB', {
        month: 'long', year: 'numeric'
        }).replace(/ /g, ' ');
        
        await this.setState({formated_date:formattedDate})
        this.fetchALLFaculty();
    }

    fetchALLFaculty=async()=>{
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFaculty`,{
            headers: { Authorization: localStorage.getItem('token')}
        });
        if (data.msg) console.log(data.msg);
        else {
            this.setState({facultyList:data.facultyList});
        }
     
    }

    handleFacultySelect = async(e)=>{
        await this.setState({selected_faculty:e.target.value})
    }

    handleSelectedFacultyClick = async(e) =>{
        e.preventDefault();
          const {selected_faculty} = this.state;

        if(selected_faculty=='')
        {
            alert('Pelese Select Faculty First!')
        }
        else{
            window.open(`/SalarySlip?faculty_id=${selected_faculty}`,'_blank')
        }   
    }

    handleGenerateSalarySlipClick = async() =>{
        try{
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/generateSalarySlip`,{},{
                headers: { Authorization: localStorage.getItem('token')}
            });
            if (data.msg){
                alert(data.msg)
            } 
        }
        catch{
            alert('Something Went Wrong!');
        }
    }

    

    render(){
      const {facultyList,formated_date,selected_faculty} = this.state;

        return(
            <div className="container mt-3">
                  <div class="card">
                    <div class="card-body">
                        <h5 class="card-title mb-3">View Salary Slip</h5>
                        <hr />

                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e)=>this.handleFacultySelect(e)}>
                            <option selected>Select Faculty</option>
                            {facultyList.length > 0 &&
                                facultyList.map((fee) => {
                                    return (
                                        <option key={fee.faculty_id} value={fee.faculty_id}>
                                            {fee.name}
                                        </option>
                                    );
                            })}
                        </select>
                        <div className="btn-container d-flex justify-content-between mt-3">
                            <a target="_blank" className="btn btn-primary" onClick={(e)=>{this.handleSelectedFacultyClick(e)}}>
                                View Selected Faculty's Salary Slip
                            </a>
                            <a href="/SalarySlip" target="_blank" className="btn btn-primary">
                                View All Salary Slip
                            </a>
                        </div>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        <h5 class="card-title mb-3">Generate Salary Alip</h5>
                        <hr />

                        <div className="btn-container d-flex justify-content-between mt-3">
                            <button className="btn btn-primary" onClick={(e)=>this.handleGenerateSalarySlipClick()}>
                                Generate Salary Slip For {formated_date}
                            </button>
                            
                        </div>
                    </div>
                </div>        
            </div>
        )
    }
}

export default GenerateSalarySlip;