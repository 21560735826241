import React, { Component } from 'react';

class BasicDetails extends Component {
    handleInputChange = (e) => {
        const { onStudClgIdChange } = this.props;
        const studClgId = e.target.value;
        onStudClgIdChange(studClgId);
    };

    render() {
    return (
      <>
      
        <div className="col-lg-5 col-md-5 col-sm-12 mt-3">
          <div className="card">
            <h5 className="card-title p-2">Basic Details</h5>
            <hr />
            <div className="card-body">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="VU1F000000"
                  name="stud_clg_id"
                  onChange={this.handleInputChange}
                />
                <label htmlFor="floatingInput">Student College ID</label>
              </div>

              <select className="form-select mb-3" onChange={(e)=>this.props.handleSelectAy(e.target.value)} aria-label="Default select example" name='ay'>
                  <option selected>Select Academic Year</option>
                  <option value="2023-24">2023-24</option>
                  <option value="2024-25">2024-25</option>
                  <option value="2025-26">2025-26</option>
              </select>

              <div className="d-flex justify-content-between">
                <button className="btn btn-primary" onClick={(e)=>this.props.onFetchClick()}>Fetch</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BasicDetails;
