import React, { Component } from 'react';

class ReceiptTable extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
        const content = this.props.recipts;
        const {selectedFeeHead,students_name} = this.props
        var cardEle = []
        if(content){
            if(students_name!=''){
                content.filter(e=>{
                    return (String(e.name).toLowerCase().includes(String(students_name).toLowerCase()) || 
                    String(e.stud_clg_id).toLowerCase().includes(String(students_name).toLowerCase()))}).map((e,i)=>{
                        cardEle.push(
                            <div className="col-sm-6 mb-3 my-3 mb-sm-0">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"><b>Receipt No:</b>{e.receipt_id}</h5>
                                        <p className="card-text">
                                            <h6>College Id Number: {String(e.stud_clg_id).toUpperCase()}</h6>
                                            <h6>Name: {String(e.name).toUpperCase()} </h6>
                                            <h6>Branch: {String(e.bname).toUpperCase()}</h6>
                                            <h6>Program: {String(e.program).toUpperCase()}</h6>
                                            <h6>Academic Year: {e.academic_year}</h6>
                                            <h6>Date: {new Date(e.date_of_payment).toLocaleDateString('en-IN')}</h6>
                                            <h6>Amount: {e.amount}</h6>
                                        </p>
                                    </div>
                                    <a className='btn btn-primary' href={`/receipt-print?id=${e.receipt_id}&fh_id=${selectedFeeHead}`} target='_blank'>Print</a>
                                </div>
                            </div>
                        )
                    })
            }else{
                content.map((e,i)=>{
                    cardEle.push(
                        <div className="col-sm-6 mb-3 my-3 mb-sm-0">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"><b>Receipt No:</b>{e.receipt_id}</h5>
                                    <p className="card-text">
                                        <h6>College Id Number: {String(e.stud_clg_id).toUpperCase()}</h6>
                                        <h6>Name: {String(e.name).toUpperCase()} </h6>
                                        <h6>Branch: {String(e.bname).toUpperCase()}</h6>
                                        <h6>Program: {String(e.program).toUpperCase()}</h6>
                                        <h6>Academic Year: {e.academic_year}</h6>
                                        <h6>Date: {new Date(e.date_of_payment).toLocaleDateString('en-IN')}</h6>
                                        <h6>Amount: {e.amount}</h6>
                                    </p>
                                </div>
                                <a className='btn btn-primary' href={`/receipt-print?id=${e.receipt_id}&fh_id=${selectedFeeHead}`} target='_blank'>Print</a>
                            </div>
                        </div>
                    )
                })
            }
           
        }

        return(
            <div className="row">
                <hr className='m-3'/>
                {cardEle.length>0 ? cardEle : <h5 className='text-center'>No Receipts On Specified Date</h5>}
            </div>
        )
    }
}

export default ReceiptTable;