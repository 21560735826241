import { useState } from "react";
import axios from "axios";

const AddFeeHeadForm = () => {
  const [feehead, setFeehead] = useState("");
  const [message, setMessage] = useState("");

  const handleAddFeeHead = (e) => {
    e.preventDefault();
    if (feehead) {
      addData();
    } else {
      alert("Please enter a Fee Head");
    }
  };

  const addData = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addFeeHead`,
        { name: feehead },{
          headers: { Authorization: localStorage.getItem('token')}
        }
      );
      if (data.success === true) {
        alert(`Fee Head ${feehead} added successfully!`);
        setFeehead('')

      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-3 mb-3">
      <h3 className="">Add Fee Heads</h3>
      <form onSubmit={handleAddFeeHead}>
          <div className="form-floating mb-3 col-lg-4">
            <input
                className="form-control"
                id = 'feehead'
                type="text"
                name="feehead"
                placeholder="Tuition Fees"
                value={feehead}
                onChange={(e) => {
                  setFeehead(e.target.value);
                }}
              />
            <label htmlFor="feehead">Fee Head</label>

          </div>
          <div className="buttonContainer">
            <button type="submit" className="btn btn-primary">
              Add Fee Head
            </button>
          </div>
      </form>
    </div>
  );
};

export default AddFeeHeadForm;