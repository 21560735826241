import axios from "axios";
import React, { Component } from "react";

class SalaryForm extends Component{
    constructor(props){
        super(props)
        this.state={
            facultyList:'',
            salaryComponents:{},
            da:0,
            hra:0,
            ta:0,
            pf:0,
            ptax:0,
            others_deductions : [],
            title:'',
            amout:0,
            faculty_id:'',
        }
    }

    componentDidMount=async()=>{
        this.fetchALLFaculty();
    }

    fetchALLFaculty=async()=>{
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFaculty`,{
            headers: { Authorization: localStorage.getItem('token')}
        });
        if (data.msg) console.log(data.msg);
        else {
            this.setState({facultyList:data.facultyList});
        }
     
    }

    handleFacultySelect=async(e)=>{
        this.setState({salaryComponents:{}});

        this.setState({faculty_id:e.target.value});

        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/getSalaryData`,{
            faculty_id:e.target.value
        },{
            headers: { Authorization: localStorage.getItem('token')}
        });
        if (data.msg) console.log(data.msg);
        else {
            console.log(data)
            this.handleGetDeductions();
            this.setState({salaryComponents:data.data[0]});
            this.setState({pf:(Number(data.data[0].basic_earn)*Number(data.data[0].pf))/100});
            this.setState({ta:data.data[0].ta});
            this.setState({ptax:data.data[0].ptax});
            this.setState({da: (Number(data.data[0].basic_earn)*Number(data.data[0].da))/100})
            this.setState({hra: (Number(data.data[0].basic_earn)*Number(data.data[0].hra))/100})
        }
    }

    handleInputChange = async(e) => {

        const { name, value } = e.target;
    
        await this.setState((prevState) => ({
            salaryComponents: {
            ...prevState.salaryComponents,
            [name]: value,
          },
        }));

        const {salaryComponents} = this.state;

        if(name=='basic_earn'){
            this.setState({pf:(Number(salaryComponents.basic_earn)*Number(salaryComponents.pf))/100});
            this.setState({ta:salaryComponents.ta});
            this.setState({ptax:salaryComponents.ptax});
            this.setState({da: (Number(salaryComponents.basic_earn)*Number(salaryComponents.da))/100})
            this.setState({hra: (Number(salaryComponents.basic_earn)*Number(salaryComponents.hra))/100})
        }
      };

    handleSaveInformationCllick = async() =>{
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/salaryFormData`,{
            ...this.state.salaryComponents,
            da:this.state.da,
            hra:this.state.hra
        },{
            headers: { Authorization: localStorage.getItem('token')}
        });

        if(data.msg){
            alert(data.msg)
        }
    }

    handleAddMoreBtn = async() =>{
        const {title, amout,faculty_id} = this.state;

        if(title.trim()=='' || amout==0 || faculty_id===''){
            alert('Please Enter Title and amount or you have not selected faculty');
        }
        else{
            await axios.post(`${process.env.REACT_APP_BASE_URL}/addOtherDeductions`,{
                title:title,
                amout:amout,
                id:faculty_id
            },{
                headers: { Authorization: localStorage.getItem('token')}
            }).then(
                response=>{
                    this.setState({title:'',amout:0})
                    this.handleGetDeductions();
                }
            ).catch(
                (e)=>{
                    alert('Something Went Wrong!')
                }
            )
        }

    }
    
    handleGetDeductions = async() =>{
        await axios.post(`${process.env.REACT_APP_BASE_URL}/getOtherDeductions`,{
            id:this.state.faculty_id
        },{
            headers: { Authorization: localStorage.getItem('token')}
        }).then(
            response=>{
                console.log(response.data)
                this.setState({others_deductions:response.data.data})
            }
        ).catch(
            (e)=>{
                console.log(e)
                alert('Something Went Wrong!')
            }
        )
    }

    handleDeleteClick = async(e) =>{
        await axios.post(`${process.env.REACT_APP_BASE_URL}/deleteOtherDeductions`,{
            id:e
        },{
            headers: { Authorization: localStorage.getItem('token')}
        }).then(
            response=>{
                alert(
                    'Deleted!'
                );
                this.handleGetDeductions()
            }
        ).catch(
            (e)=>{
                console.log(e)
                alert('Something Went Wrong!')
            }
        )
    }

    render(){
      const {facultyList,salaryComponents,da,hra,pf,ta,ptax,others_deductions} = this.state;

        return(
            <div className="container mt-3">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title mb-3">Salary Form</h5>
                        <hr />

                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={(e)=>this.handleFacultySelect(e)}>
                            <option selected>Select Faculty</option>
                            {facultyList.length > 0 &&
                                facultyList.map((fee) => {
                                    return (
                                        <option key={fee.faculty_id} value={fee.faculty_id}>
                                            {fee.name}
                                        </option>
                                    );
                            })}
                        </select>

                        <button className="btn btn-primary mt-3" onClick={(e)=>{this.handleSaveInformationCllick()}}>
                            Save Informations
                        </button>

                        <div className="row mt-3">
                            <hr />
                            <h5 className="">EMP Details</h5>
                            <hr />
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="grade" value={salaryComponents.grade} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Grade</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="depatment" class="form-control" id="floatingInput" value={salaryComponents.department} readOnly/>
                                    <label for="floatingInput">Depatment</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="pfno" class="form-control" id="floatingInput" value={salaryComponents.pfno} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">PF No.</label>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="division" class="form-control" id="floatingInput" value={salaryComponents.division} readOnly/>
                                    <label for="floatingInput">Devisoion</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="panno" value={salaryComponents.panno} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">PAN No.</label>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="bank_account_no" value={salaryComponents.bank_account_no} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Bank Account No</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <hr />
                            <h5 className="">Earnings</h5>
                            <hr />
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="basic_earn" value={salaryComponents.basic_earn} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Earned Basic</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="da" class="form-control" id="floatingInput" value={da} readOnly/>
                                    <label for="floatingInput">D.A.</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="hra" class="form-control" id="floatingInput" value={hra} readOnly/>
                                    <label for="floatingInput">H.R.A.</label>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="ta" class="form-control" id="floatingInput" value={ta} readOnly/>
                                    <label for="floatingInput">TA</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="agp" value={salaryComponents.agp} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">AGP</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="spl_allow" value={salaryComponents.spl_allow} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Spl_Pay Allow</label>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="other_allow" value={salaryComponents.other_allow} class="form-control" id="floatingInput" onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Other Allow</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <hr />
                            <h5 className="">Deductions</h5>
                            <hr />
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="pf" class="form-control" id="floatingInput" value={pf} readOnly/>
                                    <label for="floatingInput">Prov. Fund</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="ptax" class="form-control" id="floatingInput" value={ptax} readOnly/>
                                    <label for="floatingInput">P. Tax</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="income_tax" class="form-control" id="floatingInput" value={salaryComponents.income_tax} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Income Tax</label>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-3">
                                <div class="form-floating mb-3 w-100">
                                    <input type="text" name="loan_installment" class="form-control" id="floatingInput" value={salaryComponents.loan_installment} onChange={(e)=>this.handleInputChange(e)}/>
                                    <label for="floatingInput">Loan Installments</label>
                                </div>
                            </div>
                            

                            <div className="col-lg-4 mt-3">
                                <div className="form-floating mb-3 w-100">
                                    <input type="text" name="title" className="form-control" id="floatingInput" value={this.state.title} onChange={(e)=>{this.setState({title:e.target.value})}} />
                                    <label htmlFor="floatingInput">Title</label>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <div className="form-floating mb-3 w-100">
                                    <input type="text" name="other_deduction" className="form-control" id="floatingInput" value={this.state.amout} onChange={(e)=>{this.setState({amout:e.target.value})}}/>
                                    <label htmlFor="floatingInput">Amount</label>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-3">
                                <div className="form-floating mb-3 w-100">
                                    <button className="btn btn-primary" onClick={()=>{this.handleAddMoreBtn()}}>Add more</button>
                            </div>
                            </div>

                            {others_deductions.length > 0 ?
                                <table  class="table">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Amount</th>
                                            <th>Delete</th>
                                            {/* Add more column headers as needed */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {others_deductions.map((e, index) => (
                                            <tr key={index}>
                                                <td>{e.title}</td>
                                                <td>{e.amount}</td>
                                                <td><button className="btn btn-danger" onClick={()=>this.handleDeleteClick(e.od_id)}>Delete</button></td>
                                                {/* Add more columns with respective content */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    :
                                    <></>
                                }


                        </div>

                    </div>
                </div>           
            </div>               
        )
    }
}

export default SalaryForm;