import React, { Component } from 'react';
import BasicDetails from './collectComponents/basicDetails';
import StudentDetails from './collectComponents/studentDetails';
import BankDetails from './collectComponents/bankDetails';
import PaymentDetails from './collectComponents/paymentDetails';
import axios from 'axios';
import Feetable from './collectComponents/feeTable';

class CollectFee extends Component {
    constructor(props) {
        super(props);
        this.state = {
        isFee:0,
        new_amount:0,
        ay:'',
        amount:'',
        studClgId: '',
        fetchedData: '',
        feeStructure: '',
        studentDetails: {
            name: '', // Fetch the actual name from your data source
            branch: '', // Fetch the actual branch from your data source
            program: '', // Fetch the actual program from your data source
            category: '', // Fetch the actual category from your data source
            total_fee: '', // Fetch the actual category from your data source
            scholership: '', // Fetch the actual category from your data source
            payable: '', // Fetch the actual category from your data source
          },
          bankDetails: {
            mop:'',
            bankName: '',
            bankBranch: '',
            checkDate: '',
            micr: '',
            code: '',
            utr: '',
            receiptDate: '',
          },
          bankDetailsArray: [],
        };
    }

  handleFetchClick = () => {
    new axios.post(
      `${process.env.REACT_APP_BASE_URL}/getFeeStructure`,
      {
        clgId:this.state.studClgId,
        ay:this.state.ay
      },
      {
        headers: { Authorization: localStorage.getItem('token')}
      }
    ).then(res=>{
      this.setState({studentDetails:res.data.details})
    }).catch(
      e=>{alert(e.response.data.msg)}
    )

  };

  handleStudClgIdChange = (studClgId) => {
    this.setState({ studClgId });
  };

  handleBankDetailsChange = (name, value) => {
    this.setState((prevState) => ({
      bankDetails: {
        ...prevState.bankDetails,
        [name]: value,
      },
    }));
  };

  handleAddPayment = (e) => {
    this.setState((prevState) => ({
      bankDetailsArray: [...prevState.bankDetailsArray, e],
    }));
  };

  handleUpdateArray=(d)=>{
    this.setState({
      bankDetailsArray: d,
    });
  }

  handleSaveTable=()=>{
    new axios.post(
      `${process.env.REACT_APP_BASE_URL}/collectFee`,
      {
        clgId:this.state.studClgId,
        ay:this.state.ay,
        bankDetails:this.state.bankDetails,
        payment:this.state.bankDetailsArray,
        payable:this.state.studentDetails.payable,
        isFee:this.state.isFee,
        amount:this.state.new_amount,
        total_paid:0
      },
      {
        headers: { Authorization: localStorage.getItem('token')}
      }
    ).then(res=>{
      alert(res.data.msg)
    }).catch(
      e=>{alert(e.response.data.msg)}
    )
  }

  handleSelectAy=(e)=>{
    this.setState({
      ay: e
    });
  }

  handleisFeeChange=(e)=>{
    this.setState({isFee:e});
  }

  handleNewAmountChange=(e)=>{
    this.setState({new_amount:e.target.value})
  }

  render() {
    const {  studentDetails,bankDetails,bankDetailsArray } = this.state;
    return (
      <>
        <div className="container">
            <div className="row justify-content-between">
                <BasicDetails
                    handleSelectAy={(e)=>this.handleSelectAy(e)}
                    onFetchClick={() => this.handleFetchClick()}
                    onFetchFeeStructureClick={(studClgId) => this.handleFetchFeeStructureClick(studClgId)}
                    onStudClgIdChange={(studClgId) => this.handleStudClgIdChange(studClgId)}
                />
                <StudentDetails
                    studentDetails={studentDetails}
                />

                <BankDetails onBankDetailsChange={this.handleBankDetailsChange}/>
                <div className="d-flex justify-content-between">
                <PaymentDetails 
                onAddBankDetail={this.handleAddPayment} handleisFeeChange={this.handleisFeeChange}
                isFee={this.state.isFee} handleNewAmountChange = {this.handleNewAmountChange}
                handleSaveTable = {this.handleSaveTable}

                ></PaymentDetails>
                {
                  bankDetailsArray.length>0 ?
                  <div className="col-lg-5">
                      <Feetable 
                      tableData = {bankDetailsArray}
                      setTableData = {this.handleUpdateArray}
                      handleSaveTable = {this.handleSaveTable}
                      />
                  </div>
                   : <></>
                }
                </div>
                
            </div>
        </div>
      </>
    );
  }
}

export default CollectFee;
