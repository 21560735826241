import axios from 'axios';
import React, { Component } from 'react';

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFee:[{}],
      payment:{
    },
    }
  }
  componentDidMount(){
    this.FetchAllFh();
  }
  
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
          payment:{
            ...prevState.payment,
            [name]:value
          }
        }));    
      };

      FetchAllFh = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFhCollect`,{
              headers: { Authorization: localStorage.getItem('token')}
            });
            if (!data.found) console.log(data.error);
            else {
                this.setState({allFee:data.result});
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleCLickAdd = () =>{
      console.log(this.state.payment)
      const {onAddBankDetail} = this.props;
      onAddBankDetail(this.state.payment);
    }

    render() {
      const {allFee} = this.state;
    return (
      <>
        <div className="col-lg-5 col-md-5 col-sm-12 mt-3">
          <div className="card">
            <h5 className="card-title p-2">Payment Details</h5>
            <hr />

            <div className="card-body">
              <select className="form-select mb-3" aria-label="Default select example" name='feeHeads' onChange={(e)=>this.props.handleisFeeChange(e.target.value)}>
                  <option selected>Select Payment Type</option>
                  <option value={0}>Other Fee</option>
                  <option value={1}>Fee</option>
              </select>
            </div>
           
            {
              parseInt(this.props.isFee)==0 ?
                <div className="card-body">
                    <select className="form-select mb-3" aria-label="Default select example" name='feeHeads' onChange={(e)=>this.handleInputChange(e)}>
                        <option selected>Select Fee Head</option>
                        {allFee.length > 0 &&
                                    allFee.map((fee) => {
                                        return (
                                            <option key={fee.fh_id} value={fee.fh_id}>
                                                {fee.head_name}
                                            </option>
                                        );
                                    })}
                    </select>

                    <div className="form-floating mb-3">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInput"
                      name="quentity"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="floatingInput">Quentity</label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      name="amount"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="floatingInput">Amount</label>
                  </div>

                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={(e)=>this.handleCLickAdd()}>Add</button>
                  </div>

                </div>:
                <div className="card-body">
            
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      name="new_amount"
                      onChange={this.props.handleNewAmountChange}
                    />
                    <label htmlFor="floatingInput">Amount</label>
                  </div>

                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={(e)=>this.props.handleSaveTable()}>Collect Fee</button>
                  </div>

                </div>
            }
              
          </div>
        </div>
      </>
    );
  }
}

export default PaymentDetails;
