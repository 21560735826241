import axios from 'axios';
import React, { Component } from 'react';

class Dashboard extends Component {

    constructor(props){
        super(props);
        this.state={
            collection:0,
            total:0,
            balance:0,
            collectionH:0,
            totalH:0,
            balanceH:0,
            allFee:[],
            fh_id:1,
        }
    }

    componentDidMount=()=>{
        this.FetchDashBoardOver();
        this.FetchDashBoardHead();
        this.FetchAllFh();
    }

    FetchDashBoardOver = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/overview`,{
                headers: { Authorization: localStorage.getItem('token')}
              });
            this.setState({collection:data.collection});
            this.setState({total:data.total});
            this.setState({balance:data.balance});
        } catch (error) {
            console.log(error);
        }
    };

    FetchDashBoardHead = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/headwiseOverview?ay=${this.state.ay}&fh_id=${this.state.fh_id}`,{
                headers: { Authorization: localStorage.getItem('token')}
              });
            this.setState({collectionH:data.collection});
            this.setState({totalH:data.total});
            this.setState({balanceH:data.balance});
        } catch (error) {
            console.log(error);
        }
    };

    FetchAllFh = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`,{
              headers: { Authorization: localStorage.getItem('token')}
            });
            if (!data.found) console.log(data.error);
            else {
                this.setState({allFee:data.result});
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleInputChange=async(e)=>{
        await this.setState({fh_id:e.target.value});
        this.FetchDashBoardHead();
    }

    render(){
      const {allFee} = this.state;
        const {collection,total,balance,collectionH,totalH,balanceH} = this.state;
        return(
            <>
                <div className="container">
                    <div className="card mt-3" >
                        <div className="card-body">
                            <h5 className="card-title">Overall Collection</h5>
                            <div className="row justify-content-around">
                                <div class="card m-3 col-lg-3" >
                                    <div class="card-body">
                                            <h5 class="card-title">Total</h5>
                                            <h6>{total}</h6>
                                    </div>
                                </div>
                                <div class="card m-3 col-lg-3" >
                                    <div class="card-body">
                                            <h5 class="card-title">Collected</h5>
                                            <h6>{collection}</h6>
                                    </div>
                                </div>
                                <div class="card m-3 col-lg-3" >
                                    <div class="card-body">
                                            <h5 class="card-title">Balance</h5>
                                            <h6>{balance}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-3" >
                        <div className="card-body">
                        <select className="form-select mb-3" aria-label="Default select example" name='feeHeads' onChange={(e)=>this.handleInputChange(e)}>
                            {allFee.length > 0 &&
                                        allFee.map((fee) => {
                                            return (
                                                <option key={fee.fh_id} value={fee.fh_id}>
                                                    {fee.head_name}
                                                </option>
                                            );
                                        })}
                        </select>
                            <div className="row justify-content-around">
                                <div class="card m-3 col-lg-3" >
                                    <div class="card-body">
                                            <h5 class="card-title">Total</h5>
                                            <h6>{totalH}</h6>
                                    </div>
                                </div>
                                <div class="card m-3 col-lg-3" >
                                    <div class="card-body">
                                            <h5 class="card-title">Collected</h5>
                                            <h6>{collectionH}</h6>
                                    </div>
                                </div>
                                <div class="card m-3 col-lg-3" >
                                    <div class="card-body">
                                            <h5 class="card-title">Balance</h5>
                                            <h6>{balanceH}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Dashboard;