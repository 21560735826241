import React, { Component, useEffect, useState } from "react";
// import stampImage from "./stamp.png";
import "./form.css";
import { useLocation } from "react-router-dom";
import axios from "axios";

class ReceiptPrint extends Component{
  constructor(props){
    super(props)
    this.state={
      data:{},
      fh_id:'',
      allFee:[],
    }
  }

  componentDidMount=()=>{
    const queryPara =  new URLSearchParams(window.location.search);
    const id = queryPara.get('id')
    const fh_id = queryPara.get('fh_id')

    console.log('sj')
    this.setState({ fh_id: fh_id });
    this.fetchReceiptsbyId(id, fh_id);
    this.FetchAllFh();
    
  };

  
  // const [academic_year, setAY] = useState("");

  fetchReceiptsbyId = async (id, fh_id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/fetchReceiptsId`,
        { id: id, fh_id: fh_id},{
          headers: { Authorization: localStorage.getItem('token')}
        }
      );
      this.setState({ data: response.data.receipts[0] });
    } catch  {
      console.log("error");
    }

  };

  FetchAllFh = async () => {
      try {
          const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`,{
            headers: { Authorization: localStorage.getItem('token')}
          });
          if (!data.found) console.log(data.error);
          else {
            this.setState({ allFee: data.result }); 
          }
      } catch (error) {
          console.log(error);
      }
  };

 

  numberToEnglish=(n, custom_join_character)=>{
    var string =String(n),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words;


    var and = custom_join_character || "and";

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return "zero";
    }

    /* Array of units as words */
    units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];

    /* Array of tens as words */
    tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    /* Array of scales as words */
    scales = ["", "thousand", "lacs"];

    /* Split user arguemnt into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        /* Split chunk into array of individual integers */
        ints = chunks[i].split("").reverse().map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || (!i && chunksLen)) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + " hundred");
        }
      }
    }

    return words.reverse().join(" ");
  }

  render(){
    const {data,fh_id,allFee} = this.state;

    console.log(data);
    var total = 0;
  var wordsfee = "";
  if(data!={})
  {
    wordsfee = this.numberToEnglish(Number(data.amount));

  }

  var feeD = [];

  if (fh_id=='' && (data.length>0 || data.fh_id!=undefined)) {

    // console.log(wordsfee)

      let fh_ids = JSON.parse(data.fh_id)
      const sortedAllFee = fh_ids.sort((a, b) => a.feeHeads - b.feeHeads);
      sortedAllFee.map((e, i) => {
      let headName = ''
      for (const item of allFee) {
        if (item.fh_id == Number(e.feeHeads)) {
          headName = item.head_name;
          console.log(headName)
          break;
        }
      }
     feeD.push(
        <tr key={i}>
          <td className="">{i + 1}</td>
          <td className="">{headName}</td>
          <td className="">{e.amount}</td>
        </tr>
      );
    });
  }else{
    let headName = ''
    for (const item of allFee) {
      if (item.fh_id == data.fh_id) {
        headName = item.head_name;
        console.log(headName)
        break;
      }
    }

    feeD.push(
      <tr>
        <td className="">{1}</td>
        <td className="">{headName}</td>
        <td className="">{data.amount}</td>
      </tr>
    );
  }

var date_of_payment = 0;
var date_of_check = 0;
if(data){
	date_of_payment =new Date(data.date_of_payment).toLocaleDateString('en-IN');
	// date_of_check =new Date(data.check_date).toLocaleDateString("el-GB");
}
  return (

    <>
      {data && (
        <>
          <div className="container" style={{ fontSize: "12px" }}>
            <center>
              <button
                onClick={(e) => {
                  e.target.style.display = "none";
                  window.print();
                }}
              >
                Print
              </button>
            </center>
            <img src="/images/header.png" style={{ width: "100%" }} alt="" />
            <br />
            <div className="header">
              <br />

              <br />
              <div className="header-row">
                <h5 style={{ fontWeight: "bold" }}>
                  Receipt No: {data.receipt_id}
                </h5>
                <h5 style={{ fontWeight: "bold" }}>Receipt</h5>
                <h5 style={{ fontWeight: "bold" }}>
                   Date: {new Date(data.date_of_payment).toLocaleDateString('en-IN')} 
                </h5> 
              </div>
            </div>
            <div
              className="main"
              style={{ flexDirection: "column", gap: "0.5rem" }}
            >
              <div className="fee-para">
                <p>
                  College ID: {data.stud_clg_id} <br />
                  Name : {data.name} <br />
                  Course : {data.program} {data.bname} A.Y. of {data.academic_year} <br />
                  <span style={{ fontWeight: "bold" }}>
                    Category : ({data.category})
                  </span>
                  <br />
                </p>
              </div>
              <div className="tab">
                <table
                  className="receipt"
                  style={{
                    width: "100%",
		
                    borderCollapse: "collaps",
                    padding: "2px",
                  }}
                >
                  <tbody>
                    <tr className="table-head">
                      <td className="rtd">Sr.</td>
                      <td className="rtd" style={{ width: "70%" }}>
                        Particulars
                      </td>
                      <td className="rtd">Amount</td>
                    </tr>
                    {feeD.length > 0 && feeD}
                    <tr>
                      <td colSpan="2" className="words rtd">
                        Total Amount Paid
                      </td>
                      <td className="words rtd" style={{ fontWeight: "bold" }}>
                        {String(data.amount).toLocaleUpperCase()}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <p>
                  Received{" "}
                  <b style={{ fontWeight: "bold" }}>
                    {String(wordsfee).toLocaleUpperCase()}
                  </b>{" "}
                  by{" "}
                  {data.dd_no ? (
                    <>
                      Draft No. :
                      <span className="st_draft">{data.dd_no}</span> drawn on{" "}
                      {new Date(data.check_date).toLocaleDateString("el-GR")}
                      <span className="st_bank">
                        {" "}
                        {data.bank_name}, {data.bank_branch}
                      </span>
                    </>
                  ) : data.cheque_no ? (
                    <>
                      Cheque No. :
                      <span className="st_draft">{data.check_number}</span>{" "}
                      {/* drawn on {data[0].check_date} */}
                      drawn on {date_of_check}
                      <span className="st_bank">
                        {data.bank_name}, {data.bank_branch}
                      </span>
                    </>
                  ) : data.trancation_number ? (
                    <>
                      <span className="st_draft">
                        {" "}
                        the transaction id of {data.trancation_number} Via
                        {
                          data.trancation_number[0]=='V' ?
                          <>&nbsp;payment gateway.</>:
                          <>&nbsp;NEFT on {}.</>
                        }
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="st_draft"> the Cash on </span>{" "}
                    </>
                  )}{" "}
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    {
                      Number(data.balance_amount)>0 ? <>Balance : {data.balance_amount}{" "}</> :
                      <>Refunable : {Math.abs(data.balance_amount)}{" "}</>
                    }
                    
                  </span>
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Note : ({data.note})
                  </span>
                </p>
                <p>
                  This receipt is computer generated no Signature is required
                </p>
                <p>DD payment is subject to realization</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  }
};

export default ReceiptPrint;
