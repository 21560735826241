import axios from 'axios';
import React, { Component } from 'react';

class StudentsReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
          from_date:'',
          to_date:'',
          allFee:[],
          selectedHead:'',
          branches:[],
          selectBranch:'',
          branch_id:'',
          stud_clg_id:'',
          ay:''
        };
    }

componentDidMount(){
  // this.handleApplyFilter(this.state.selectedFeeHead,this.state.dateR)
}

handleGenerateHeadwiseReport=async()=>{
  try{
    const {stud_clg_id,ay} = this.state

    if(stud_clg_id!='' && ay!=''){
      const data = await new axios.post(`${process.env.REACT_APP_BASE_URL}/studentWiseReciept`,{
        stud_clg_id:stud_clg_id,
        ay:ay
      },{
        headers: { Authorization: localStorage.getItem('token')}
      })

        const blob = new Blob([data.data.data], { type: "text/csv" });
        const csvURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = csvURL;
        downloadLink.download = "data.csv";
        downloadLink.click();
        URL.revokeObjectURL(csvURL);
        downloadLink.remove();
    }
    else{
      alert("Provide Proper Data");
    }
  }
  catch(err){
    console.log(err)
    alert('Something Bad happend!')
  }
}

  render() {
    const {  allFee,branches } = this.state;
    return (
      <>
        <div className="container">
            <div className="row justify-content-between">
                <h3 className='my-3'>Student Fee Report</h3>
                <div className="col-md-3">
                    <input className='form-control' type="text" id='clgId' placeholder='College ID' onChange={(e)=>{
                      this.setState({stud_clg_id:e.target.value})
                    }}/>
                </div>

                <div className="col-md-3">
                    <select className='form-select' onChange={(e)=>{
                      this.setState({ay:e.target.value})
                    }}>
                      <option value="">Select AY</option>
                      <option value="2023-24">2023-24</option>
                      <option value="2024-25">2024-25</option>
                    </select>
                </div>
               
                <div className="col-md-3">
                    <button className='btn btn-primary' onClick={(e)=>{this.handleGenerateHeadwiseReport()}}>Generate Report</button>
                </div>
            </div>
            
        </div>
      </>
    );
  }
}

export default StudentsReports;