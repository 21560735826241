import React, { Component } from 'react';
import ReceiptTable from './recieptComponents/ReceiptTable';
import axios from 'axios';

class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
        studClgId: '',
        students_name:'',
        receipts:[{id:1},{id:2}],
        allFee:[{}],
        selectedFeeHead:'',
        dateR:new Date(),
        dateE:new Date(),
        };
    }

    componentDidMount(){
      this.FetchAllFh();
      this.handleApplyFilter(this.state.selectedFeeHead,this.state.dateR,this.state.dateE)
    }

FetchAllFh = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`,{
          headers: { Authorization: localStorage.getItem('token')}
        });
        if (!data.found) console.log(data.error);
        else {
            this.setState({allFee:data.result});
        }
    } catch (error) {
        console.log(error);
    }
};

handleFeeHeadChange=(e)=>{
  this.setState({selectedFeeHead:e.target.value})
}

handleDateRChange=(e)=>{
  this.setState({dateR:new Date(e.target.value)})
}

handleDateEChange=(e)=>{
  this.setState({dateE:new Date(e.target.value)})
}

handleApplyFilter=async (fh_id,dateR,dateE)=>{
  try {
        const newDateR = new Date(dateR).toLocaleDateString('en-CA');
        const newDateE= new Date(dateE).toLocaleDateString('en-CA');
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/getGeneralReceipt`,{fh_id:fh_id,dateR:newDateR,dateE:newDateE},{
          headers: { Authorization: localStorage.getItem('token')}
        });
        this.setState({receipts:data.receipts});
    } catch (error) {
        console.log(error);
    }
}

handleApplyFilterClick = async()=>{
  this.handleApplyFilter(this.state.selectedFeeHead,this.state.dateR,this.state.dateE);
}

  render() {
    const {  studentDetails,bankDetails,allFee,dateR } = this.state;
    return (
      <>
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <h3 className='my-3'>Generate Fee Receipt</h3>
                <div className="col-md-3">
                  <label htmlFor="">Select Fee Head</label>
                <select className="form-select mb-3" aria-label="Default select example" name='feeHeads' onChange={(e)=>this.handleFeeHeadChange(e)}>
                    <option selected>Select Fee Head</option>
                    {allFee.length > 0 &&
                                allFee.map((fee) => {
                                    return (
                                        <option key={fee.fh_id} value={fee.fh_id}>
                                            {fee.head_name}
                                        </option>
                                    );
                                })}
                </select>
                </div>
                <div className="col-md-3">
                  <label htmlFor="">From Date</label>
                    <input type="date" className='form-control' name="date_of_reciept" id="" onChange={(e)=>this.handleDateRChange(e)} />
                </div>
                <div className="col-md-3">
                  <label htmlFor="">To Date</label>
                    <input type="date" className='form-control' name="date_of_reciept" id="" onChange={(e)=>this.handleDateEChange(e)} />
                </div>
                <div className="col-md-3">
                    <button className='btn btn-primary' onClick={()=>this.handleApplyFilterClick()}>Apply Filter</button>
                </div>
                
                <div className="col-lg-4">
                    <input type="text" className='form-control' name="students_name" placeholder='Student Name/Student ID' id="" onChange={(e)=>this.setState({students_name:e.target.value})} />
                </div>

                <div className="col-lg-12 col-md-12">
                    <ReceiptTable recipts = {this.state.receipts} selectedFeeHead={this.state.selectedFeeHead} students_name={this.state.students_name}/>
                </div>

            </div>
        </div>
      </>
    );
  }
}

export default Receipt;