import axios from 'axios';
import React, { Component } from 'react';

class RefundReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: '',
            to_date: '',
            allFee: [],
            selectedHead: '',
            branches: [],
            selectAcademicYear: '',  // Updated state variable
            academicYears: []        // Updated state variable
        };
    }

    componentDidMount() {
        this.FetchAllFh();
        this.FetchAllBranch();
        this.FetchAllAcademicYears();  // Added method call
    }

    FetchAllFh = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            if (!data.found) console.log(data.error);
            else {
                this.setState({ allFee: data.result });
            }
        } catch (error) {
            console.log(error);
        }
    };

    FetchAllBranch = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/getBranch`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            if (!data) console.log(data.error);
            else {
                this.setState({ branches: data.branches });
            }
        } catch (error) {
            console.log(error);
        }
    };

    FetchAllAcademicYears = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAcademicYears`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            if (!data) console.log(data.error);
            else {
                this.setState({ academicYears: data.academicYears });
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleFromChange = async (e) => {
        const { name, value } = e.target;
        this.setState({
            from_date: value
        });
    };

    handleToChange = async (e) => {
        const { name, value } = e.target;
        this.setState({
            to_date: value
        });
    };

    handleGenerateHeadwiseReport = async () => {
        try {
            const { from_date, to_date, selectedHead, selectAcademicYear } = this.state;

            if (from_date !== '' && to_date !== '' && selectAcademicYear !== '') {
                const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/getHeadwiseReport`, {
                    selectedHead: selectedHead,
                    from_date: from_date,
                    to_date: to_date,
                    academic_year: selectAcademicYear // Updated to use academic year
                }, {
                    headers: { Authorization: localStorage.getItem('token') }
                });

                const blob = new Blob([data.data.data], { type: "text/csv" });
                const csvURL = URL.createObjectURL(blob);
                const downloadLink = document.createElement("a");
                downloadLink.href = csvURL;
                downloadLink.download = "data.csv";
                downloadLink.click();
                URL.revokeObjectURL(csvURL);
                downloadLink.remove();
            } else {
                alert("Select Dates and Academic Year First");
            }
        } catch (err) {
            console.log(err);
            alert('Something Bad happened!');
        }
    };

    handleGenerateBalanceReport = async () => {
        try {
            const { selectAcademicYear } = this.state;

            if (selectAcademicYear !== '') {
                const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/getRefundReport`, {
                    academic_year: selectAcademicYear // Updated to use academic year
                }, {
                    headers: { Authorization: localStorage.getItem('token') }
                });

                const blob = new Blob([data.data.data], { type: "text/csv" });
                const csvURL = URL.createObjectURL(blob);
                const downloadLink = document.createElement("a");
                downloadLink.href = csvURL;
                downloadLink.download = "data.csv";
                downloadLink.click();
                URL.revokeObjectURL(csvURL);
                downloadLink.remove();
            } else {
                alert("Select Academic Year First");
            }
        } catch (e) {
            console.log(e);
            alert('Something Bad happened!');
        }
    };

    render() {
        const { academicYears } = this.state;
        return (
            <>
                <div className="container">
                    <div className="row justify-content-between">
                        <h3 className='my-3'>Generate Refund Fee Report</h3>
                        <div className="col-md-3">
                            <select className='form-select' name="academicYear" onChange={(e) => { this.setState({ selectAcademicYear: e.target.value }) }}>
                                <option value="">---Select Academic Year---</option>
                                <option value="2024-25">2024-25</option>
                                <option value="2025-26">2025-26</option>
                                {/* <option value="2024-25">2024-25</option> */}
                            </select>
                        </div>
                       
                        <div className="col-md-3">
                            <button className='btn btn-primary' onClick={this.handleGenerateBalanceReport}>Generate Report</button>
                        </div>
                    </div>
                    
                </div>
            </>
        );
    }
}

export default RefundReport;
