import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

class BankStatementPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      statementDate: "",
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchDDData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.statementDate !== this.state.statementDate && this.state.statementDate) {
      this.fetchAllDD();
    }
  }

  fetchAllDD = async () => {
    try {
      const { statementDate } = this.state;

      if (statementDate !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/printStatement`,
          {
            date: statementDate,
          },
          {
            headers: { Authorization: localStorage.getItem("token") },
          }
        );

        if (response.data.ddData) {
          this.setState({ transactions: response.data.ddData }, this.calculateTotal);
        } else {
          alert("No data");
        }
      } else {
        alert("Select date to Print Bank Statement!");
      }
    } catch (err) {
      console.error(err);
      alert("Something Went Wrong!");
    }
  };

  fetchDDData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/StatementDates`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      );
      const searchQuery = new URLSearchParams(window.location.search);
      const dateIndex = Number(searchQuery.get("id"));
      if (response.data.dates && response.data.dates[dateIndex]) {
        this.setState({ statementDate: response.data.dates[dateIndex].dates });
      } else {
        console.log("No data");
      }
    } catch (err) {
      console.log(err);
      alert("Something Went Wrong!");
    }
  };

  calculateTotal = () => {
    const total = this.state.transactions.reduce((acc, item) => acc + Number(item.amount), 0);
    this.setState({ total });
  };

  numberToEnglish = (n, customJoinCharacter) => {
    const string = n.toString();
    let units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words;

    const and = customJoinCharacter || "and";

    if (parseInt(string) === 0) {
      return "zero";
    }

    units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];

    tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

    scales = ["", "thousand", "lacs"];

    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        ints = chunks[i].split("").reverse().map(parseFloat);

        if (ints[1] === 1) {
          ints[0] += 10;
        }

        if ((word = scales[i])) {
          words.push(word);
        }

        if ((word = units[ints[0]])) {
          words.push(word);
        }

        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        if (ints[0] || ints[1]) {
          if (ints[2] || (!i && chunksLen)) {
            words.push(and);
          }
        }

        if ((word = units[ints[2]])) {
          words.push(word + " hundred");
        }
      }
    }

    return words.reverse().join(" ");
  };

  render() {
    const { transactions, statementDate, total } = this.state;
    const wordsFee = transactions.length > 0 ? this.numberToEnglish(total).toUpperCase() : "";

    return (
      <div
        className="bank-statement text-center d-flex justify-content-center"
        style={{ width: "100%" }}
      >
        <div className="main-bank-statement text-center" style={{ width: "90%" }}>
          <div className="header-bank text-center">
            <h3>Vasantdada Patil Pratishthan's College of Engineering and Visual Arts</h3>
            <p>
              Vasantdada Patil Education Complex, Eastern Express Highway Near
              Everard Nagar, Chunabhatti, Sion, Mumbai, Maharashtra 400022
            </p>
            <p>Phone: 022 2084 7226</p>
            <h4>Bank Statement</h4>
          </div>

          <div className="m-5 p-3 d-flex justify-content-around border border-1 border-black">
            <h5>Bank: SHALINI SAHAKARI BANK LTD</h5>
            <h5>Branch: Mumbai</h5>
            <h5>A/C No: 100204180000819</h5>
          </div>

          <h4 className="text-end">Date: {statementDate}</h4>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr No.</th>
                <th scope="col">Bank Name</th>
                <th scope="col">Branch</th>
                <th scope="col">DD Number</th>
                <th scope="col">DD Date</th>
                <th scope="col">MICR Code</th>
                <th scope="col">Code</th>
                <th scope="col">Amount</th>
                <th scope="col">Receipt Number</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {transactions.length > 0 &&
                transactions.map((e, i) => (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{e.bank_name}</td>
                    <td>{e.bank_branch}</td>
                    <td>{e.dd_number}</td>
                    <td>{e.dd_date}</td>
                    <td>{e.micr}</td>
                    <td>{e.dd_code}</td>
                    <td>{e.amount}</td>
                    <td>{e.receipt_id}</td>
                  </tr>
                ))}
            </tbody>
            <tbody className="table-group-divider">
              <tr>
                <th colSpan={8}>Total</th>
                <td>{total}</td>
              </tr>
            </tbody>
            <tbody className="table-group-divider">
              <tr>
                <th colSpan={9}>{wordsFee}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default BankStatementPrint;
