import React, { Component } from 'react';
import BasicDetails from './collectComponents/basicDetails';
import StudentDetails from './collectComponents/studentDetails';
import BankDetails from './collectComponents/bankDetails';
import PaymentDetails from './collectComponents/paymentDetails';
import axios from 'axios';
import Feetable from './collectComponents/feeTable';
import InstallmentForm from './collectComponents/installmentForm';
import RefundForm from './collectComponents/refundAmount';

class Refundable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        ay:'',
        amount:'',
        studClgId: '',
        fetchedData: '',
        feeStructure: '',
        amount:'',
        studentDetails: {
            name: '', // Fetch the actual name from your data source
            branch: '', // Fetch the actual branch from your data source
            program: '', // Fetch the actual program from your data source
            category: '', // Fetch the actual category from your data source
            total_fee: '', // Fetch the actual category from your data source
            scholership: '', // Fetch the actual category from your data source
            payable: '', // Fetch the actual category from your data source
          },
          bankDetails: {
            mop:'',
            bankName: '',
            bankBranch: '',
            checkDate: '',
            micr: '',
            code: '',
            utr: '',
            receiptDate: '',
          },
          bankDetailsArray: [],
          formData :{}
        };
    }

  handleFetchClick = () => {
    new axios.post(
      `${process.env.REACT_APP_BASE_URL}/getFeeStructure`,
      {
        clgId:this.state.studClgId,
        ay:this.state.ay
      },
      {
        headers: { Authorization: localStorage.getItem('token')}
      }
    ).then(res=>{
      this.setState({studentDetails:res.data.details})
    }).catch(
      e=>{alert(e.response.data.msg)}
    )

  };

  handleStudClgIdChange = (studClgId) => {
    this.setState({ studClgId });
  };

  handleAllowClick=()=>{
    new axios.post(
      `${process.env.REACT_APP_BASE_URL}/addRefundHistory`,
      {
        stud_clg_id:this.state.studClgId,
        ay:this.state.ay,
        formData:this.state.formData
      },
      {
        headers: { Authorization: localStorage.getItem('token')}
      }
    ).then(res=>{
      alert(res.data.msg)
    }).catch(
      e=>{alert(e.response.data.msg)}
    )
  }

  handleSelectAy=(e)=>{
    this.setState({
      ay: e
    });
  }

  handleAmount = (e) => {
    const { formData } = this.state;
    const {name,value} = e.target;
    console.log(formData);
    this.setState({
        formData: {
            ...formData,
            [name]: value
        }
    });
}


  render() {
    const {  studentDetails,bankDetailsArray } = this.state;
    return (
      <>
        <div className="container">
            <div className="row justify-content-between">
                <BasicDetails
                    handleSelectAy={(e)=>this.handleSelectAy(e)}
                    onFetchClick={() => this.handleFetchClick()}
                    onFetchFeeStructureClick={(studClgId) => this.handleFetchFeeStructureClick(studClgId)}
                    onStudClgIdChange={(studClgId) => this.handleStudClgIdChange(studClgId)}
                />
                <StudentDetails
                    studentDetails={studentDetails}
                />
                <RefundForm onAmountChange={(e)=>this.handleAmount(e)} 
                handleAllowClick={()=>this.handleAllowClick()}/>
            </div>
        </div>
      </>
    );
  }
}

export default Refundable;
