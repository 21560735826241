import axios from 'axios';
import React, { Component } from 'react';

class BalReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
          from_date:'',
          to_date:'',
          allFee:[],
          selectedHead:'',
          branches:[],
          selectBranch:'',
          branch_id:''
        };
    }

componentDidMount(){
  this.FetchAllFh();
  this.FetchAllBranch();
  // this.handleApplyFilter(this.state.selectedFeeHead,this.state.dateR)
}

FetchAllFh = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`,{
          headers: { Authorization: localStorage.getItem('token')}
        });
        if (!data.found) console.log(data.error);
        else {
            this.setState({allFee:data.result});
        }
    } catch (error) {
        console.log(error);
    }
};

FetchAllBranch = async () => {
  try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/getBranch`,{
        headers: { Authorization: localStorage.getItem('token')}
      });
      if (!data) console.log(data.error);
      else {
          this.setState({branches:data.branches});
      }
  } catch (error) {
      console.log(error);
  }
};

handleFromChange=async(e)=>{
  const {name,value} = e.target;
  this.setState({
    from_date:value
  })
}

handleToChange=async(e)=>{
  const {name,value} = e.target;
  this.setState({
    to_date:value
  })
}

handleGenerateHeadwiseReport=async()=>{
  try{
    const {from_date, to_date,selectedHead,branch_id} = this.state

    if(from_date!='' && to_date!=''){
      const data = await new axios.post(`${process.env.REACT_APP_BASE_URL}/getHeadwiseReport`,{
        selectedHead:selectedHead,
        from_date:from_date,
        to_date:to_date,
        branch_id:branch_id
      },{
        headers: { Authorization: localStorage.getItem('token')}
      })

        const blob = new Blob([data.data.data], { type: "text/csv" });
        const csvURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = csvURL;
        downloadLink.download = "data.csv";
        downloadLink.click();
        URL.revokeObjectURL(csvURL);
        downloadLink.remove();
    }
    else{
      alert("Select Dates First");
    }
  }
  catch(err){
    console.log(err)
    alert('Something Bad happend!')
  }
}


handleGenerateBalanceReport=async()=>{
  try{
    const {selectBranch} = this.state
    console.log(selectBranch)
    if(selectBranch!=''){
      const data = await new axios.post(`${process.env.REACT_APP_BASE_URL}/getBalReport`,{
        branch_id:selectBranch,
      },{
        headers: { Authorization: localStorage.getItem('token')}
      })

        const blob = new Blob([data.data.data], { type: "text/csv" });
        const csvURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = csvURL;
        downloadLink.download = "data.csv";
        downloadLink.click();
        URL.revokeObjectURL(csvURL);
        downloadLink.remove();
}
    else{
      alert("Select Dates First");
    }
  }
  catch(e){
    console.log(e)
    alert('Something Bad happend!')
  }
}

  render() {
    const {  allFee,branches } = this.state;
    return (
      <>
        <div className="container">
            <div className="row justify-content-between">
                <h3 className='my-3'>Generate Balance Fee Report</h3>
                <div className="col-md-3">
                    <select className='form-select' name="heads" id="" onChange={(e)=>{this.setState({selectBranch:e.target.value})}}>
                        <option value="">---Select Branch---</option>
                        {branches.length > 0 &&
                          branches.map((fee) => {
                              return (
                                  <option key={fee.branch_id} value={fee.branch_id}>
                                      {fee.bname}
                                  </option>
                            );
                      })}
                    </select>
                </div>
               
                <div className="col-md-3">
                    <button className='btn btn-primary' onClick={(e)=>{this.handleGenerateBalanceReport()}}>Generate Report</button>
                </div>
            </div>
            
        </div>
      </>
    );
  }
}

export default BalReport;