import React, { Component } from 'react';

class StudentDetails extends Component {
    
    render() {
    const { name, branch, program, category,total_fee, scholership, payable,amt_afterscholership,total_paid } = this.props.studentDetails;

    return (
      <>
        <div className="col-lg-5 col-md-5 col-sm-12 mt-3">
          <div className="card">
            <h5 className="card-title p-2">Student Details</h5>
            <hr />
            <div className="card-body d-flex flex-column">
              <span><b>Name:</b> {name}</span>
              <span><b>Branch:</b> {branch}</span>
              <span><b>Program:</b> {program}</span>
              <span><b>Category:</b> {category}</span>
              <span><b>Total Fee:</b> {total_fee}</span>
              <span><b>Scholership:</b> {scholership}</span>
              <span><b>Fee After Scholership:</b> {amt_afterscholership}</span>
              <span><b>Total Paid:</b> {total_paid}</span>
              <span><b>Payable Amount:</b> {payable}</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StudentDetails;
