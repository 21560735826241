import axios from 'axios';
import React, { Component } from 'react';

class StatementPrint extends Component
{
    constructor(props){
        super(props);
        this.state={
            statemetDates:[],
            statementData:[],
            selectedDate:''
        }
    }

    componentDidMount=async()=>{
        this.fetchDDData();
    }

    fetchDDData= async()=>{
        try{
            const data = await new axios.get(`${process.env.REACT_APP_BASE_URL}/StatementDates`,{
                headers: { Authorization: localStorage.getItem('token')}
              });
            if(data.data.dates)
            {
                this.setState({statemetDates:data.data.dates});
            }
            else{
                console.log('no data')
            }
        }
        catch{
            alert('Something Went Wrong!');
        }
    }

    handleDateSelect= async(e)=>{  
        this.setState({
            selectedDate:e.target.value
        })
    }

    handleApplyFilter=async()=>{
        window.open(`/printStatement?id=${this.state.selectedDate}`,'_blank');
    }

    render(){
        const {statemetDates} = this.state;
        return(
            <>
            <div className="container">
                <h3 className='mt-3 mb-3'>Generate Bank Statement</h3>
                <select className="form-select mb-3" aria-label="Default select example" name='feeHeads' onChange={(e)=>this.handleDateSelect(e)}>
                    <option selected>Available Statements</option>
                    {statemetDates.length > 0 &&
                                statemetDates.map((e,i) => {
                                    return (
                                        <option value={i}>
                                            {e.dates}
                                        </option>
                                    );
                    })}

                </select>

                <button className='btn btn-primary' onClick={(e)=>this.handleApplyFilter()}>Apply Filter</button>

            </div>
            </>
        )
    }
}

export default StatementPrint