import { useEffect } from 'react';
import CollectFee from './components/collect_fee/CollectFee';
import Login from './components/login/login';
import Navbar from './components/navBar/Navbar';
import Base from './components/navBar/base';
import { BrowserRouter as Router, Route, Routes, Link, Navigate  } from 'react-router-dom';
import css from './App.css'
import ReceivedScholership from './components/collect_fee/ReceivedScholership';
import ScholershipFee from './components/collect_fee/ScholershipFee';

function App() {
  useEffect=()=>{
    // console.clear();
  }
  return (
    <Router>
      {
    
    localStorage.getItem('role')=='1'?
    <Base></Base>
    :localStorage.getItem('role')=='2'
    ?
    <>
    <Navbar/>
    <Routes>
      <Route exact path="/" element={<ScholershipFee/>}/>
      {/* <Route exact path="/" element={<ReceivedScholership/>}/> */}
      <Route path="/*"
        element={
          <h5 className="container text-center mt-4">
          404! Page Not Found. Please Check That You have Proper
          authority To visit the page
          </h5>
      }
      />
    </Routes>
    </>
    :
    <Routes>
            <Route path="/*" element={<Login/>}/>
    </Routes>
      }
    </Router>
  );
}

export default App;
