import React, { Component } from 'react';

class BankDetails extends Component {
    handleInputChange = (e) => {
        const { name, value } = e.target;
        const { onBankDetailsChange } = this.props;

        onBankDetailsChange(name, value);
    };

    render() {
        return (
            <>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <div className="card">
                        <h5 className="card-title p-2">Bank Details</h5>
                        <hr />
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-lg-5">
                                    <select className="form-select mb-3" onChange={this.handleInputChange} aria-label="Default select example" name='mop'>
                                        <option selected>Mode Of Payment</option>
                                        <option value="Cash">Cash</option>
                                        <option value="DD">DD</option>
                                        <option value="NEFT">NEFT</option>
                                    </select>
                                </div>
                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name="bankName"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">Bank Name</label>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name="bankBranch"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">Bank Branch</label>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="floatingInput"
                                            name="checkDate"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">DD Date</label>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name="ddno"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">DD Number</label>
                                    </div>
                                </div>


                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name="micr"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">MICR Code</label>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name="code"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">code</label>
                                    </div>
                                </div>


                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            name="utr"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">UTR Number</label>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="form-floating mb-3">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="floatingInput"
                                            name="receiptDate"
                                            onChange={this.handleInputChange}
                                        />
                                        <label htmlFor="floatingInput">Receipt Date</label>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BankDetails;
