import React, { Component } from "react";
import axios from "axios";

class MapFeeHeadsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feeHead: "",
      feeName: "",
      priority: "",
      program: "",
      amount: "",
      tableData: [],
      allFee: [],
      ay: "",
      passing_year: "",
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    const {
      feeHead,
      feeName,
      program,
      amount,
      passing_year,
      priority,
      tableData,
    } = this.state;

    if (feeHead.trim() !== "" && amount.trim() !== "" && passing_year.trim() !== "") {
      const newRow = {
        feeHead: feeHead,
        feeName: feeName,
        program: program,
        amount: amount,
        passing_year: passing_year,
        priority: priority,
        ay: this.state.ay,
      };

      const emptyRowIndex = tableData.findIndex((row) => Object.keys(row).length === 0);

      if (emptyRowIndex !== -1) {
        const updatedTableData = [...tableData];
        updatedTableData[emptyRowIndex] = newRow;
        this.setState({ tableData: updatedTableData });
      } else {
        this.setState({ tableData: [...tableData, newRow] });
      }

      this.setState({ feeHead: "", amount: "", priority: "" });
    } else {
      window.alert("Please fill in all the fields.");
    }
  };

  handleDeleteRow = (index) => {
    const updatedTableData = [...this.state.tableData];
    updatedTableData.splice(index, 1);
    this.setState({ tableData: updatedTableData });
  };

  handleSaveTable = async () => {
    const { tableData } = this.state;
    tableData.forEach(async (e) => {
      console.log(e);
      const a = await this.addData(e.amount, e.feeHead, e.program, e.priority);
      if (a) alert("Data Saved successfully");
      else alert("Something went wrong");
    });
    this.setState({ tableData: [] });
  };

  addData = async (amt, fhname, program, priority) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mapcattofeehead`,
        {
          fh_name: fhname,
          amount: amt,
          program: program,
          passing_year: this.state.passing_year,
          priority: priority,
          ay: this.state.ay,
        },
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      );

      if (data.success === true) {
        return true;
      } else {
        alert(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  FetchAllFh = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetchAllFh`, {
        headers: { Authorization: localStorage.getItem("token") },
      });

      if (!data.found) console.log(data.error);
      else {
        this.setState({ allFee: data.result });
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.FetchAllFh();
  }

  render() {
    const { program, feeHead, ay, amount, passing_year, priority, tableData, allFee } = this.state;

    return (
      <div className="container mt-3">
        <h3>Mapping fee Heads to Categories</h3>

        <div>
          <form className="row" onSubmit={this.handleFormSubmit}>
            <div className="col-lg-5">
              <select
                className="form-select mb-3 col-lg-4"
                id="program"
                value={program}
                onChange={(e) => this.setState({ program: e.target.value })}
              >
                <option value="" disabled hidden>
                  Is Applicable to DSA students?
                </option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className="col-lg-5">
              <select
                className="form-select mb-3 col-lg-4"
                id="feeHead"
                value={feeHead}
                onChange={(e) => {
                  this.setState({ feeHead: e.target.value, feeName: e.target.options[e.target.selectedIndex].text });
                }}
              >
                <option value="" disabled hidden>
                  Select Fee Head
                </option>
                {allFee.length > 0 &&
                  allFee.map((fee) => (
                    <option key={fee.fh_id} value={fee.fh_id}>
                      {fee.head_name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-floating mb-3 col-lg-5">
              <input
                className="form-control"
                type="text"
                id="ay"
                value={ay}
                placeholder="2019-20"
                onChange={(e) => this.setState({ ay: e.target.value })}
              />
              <label htmlFor="ay">Academic Year</label>
            </div>

            <div className="form-floating mb-3 col-lg-5">
              <input
                className="form-control"
                type="number"
                id="amount"
                value={amount}
                placeholder="12345"
                onChange={(e) => this.setState({ amount: e.target.value })}
              />
              <label htmlFor="amount">Enter Amount:</label>
            </div>

            <div className="form-floating mb-3 col-lg-5">
              <input
                className="form-control"
                type="text"
                id="py"
                value={passing_year}
                placeholder="2019-20"
                onChange={(e) => this.setState({ passing_year: e.target.value })}
              />
              <label htmlFor="py">Enter Year of Admission:</label>
            </div>

            <div className="form-floating mb-3 col-lg-5">
              <input
                className="form-control"
                type="number"
                id="priority"
                value={priority}
                onChange={(e) => this.setState({ priority: e.target.value })}
              />
              <label htmlFor="priority">Priority of Deduction:</label>
            </div>

            <div>
              <button className="btn btn-primary" type="submit">
                Add Line
              </button>
            </div>
          </form>

          {tableData.length > 0 && (
            <div>
              <table className="m-3 table table-primary table-striped">
                <thead>
                  <tr>
                    <th>Fee Head</th>
                    <th>AY</th>
                    <th>Amount</th>
                    <th>Priority of Deduction</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.feeName}</td>
                      <td>{data.ay}</td>
                      <td>{data.amount}</td>
                      <td>{data.priority}</td>
                      <td>
                        <button className="btn btn-danger" onClick={() => this.handleDeleteRow(index)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button className="btn btn-primary" type="submit" onClick={this.handleSaveTable}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MapFeeHeadsForm;
