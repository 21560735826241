import axios from 'axios';
import React, { Component } from 'react';
import StatementTable from './StatementTanle/StatementTable';

class BankStatement extends Component
{
    constructor(props){
        super(props);
        this.state={
            ddData:[],
            selectedDD:[],
        }
    }

    componentDidMount=async()=>{
        this.fetchDDData();
    }

    fetchDDData= async()=>{
        try{
            const data = await new axios.get(`${process.env.REACT_APP_BASE_URL}/fetchDDData`,{
                headers: { Authorization: localStorage.getItem('token')}
              });
            if(data.data.ddData)
            {
                this.setState({ddData:data.data.ddData});
            }
            else{
                console.log('no data')
            }
        }
        catch{
            alert('Something Went Wrong!');
        }
    }

    handleDDCheck= async(e)=>{
        if (this.state.selectedDD.includes(e)) {

            const newArray = this.state.selectedDD.filter(item => item !== e);
            this.setState({selectedDD:newArray});

          } else {
            
            this.setState((prevState)=>({
                selectedDD:[...prevState.selectedDD, e]
            }))

          }


        
    }

    handleAddToStatement=async()=>{
        try{
            if(this.state.selectedDD.length>0){

                const data = await new axios.post(`${process.env.REACT_APP_BASE_URL}/AddStatement`,
                {
                    ddIds:this.state.selectedDD
                },{
                    headers: { Authorization: localStorage.getItem('token')}
                  });

                if(data.data.msg)
                {
                    alert(data.data.msg);
                    window.location.reload();
                }
                else{
                    console.log('no data')
                }
            }   
            else{
                alert('Select DD to Generate Bank Statement!')
            }         
        }
        catch{
            alert('Something Went Wrong!');
        }
    }

    render(){
        return(
            <>

            {console.log(this.state.selectedDD)}
            <div className="container">
                <h3 className='mt-3 mb-3'>Generate Bank Statement</h3>
                <StatementTable
                    data = {this.state.ddData}
                    handleDDCheck = {(e)=>this.handleDDCheck(e)}
                    handleAddToStatement = {()=>this.handleAddToStatement()}
                />
            </div>
            </>
        )
    }
}

export default BankStatement