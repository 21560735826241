import axios from 'axios';
import React, { Component } from 'react';

class StatementTable extends Component
{
    constructor(props)
    {
        super(props);
        this.state={

        }
    }

    render(){
        const {data,handleDDCheck,handleAddToStatement} = this.props;

        var tableContent = []
        data.forEach(element => {
            tableContent.push(
                <tr>
                    <td><input type="checkbox" value={element.dd_id} onChange={(e)=>{handleDDCheck(e.target.value)}}/></td>
                    <td>{element.bank_name}</td>
                    <td>{element.bank_branch}</td>
                    <td>{element.dd_number }</td>
                    <td>{new Date(element.dd_date).toLocaleDateString('en-IN')}</td>
                    <td>{element.micr}</td>
                    <td>{element.dd_code}</td>
                    <td>{element.amount}</td>
                    <td>{element.receipt_id}</td>
                </tr>
            )
        });
        return(
            <>
            <button className='btn btn-primary' onClick={(e)=>{handleAddToStatement()}}>Add to Statement</button>
            <table className="table table-bordered mt-3">
                {data && 
                <>
                <tr>
                    <th>Select</th>
                    <th>Bank Name</th>
                    <th>Branch Name</th>
                    <th>DD Number</th>
                    <th>DD Date</th>
                    <th>MICR CODE</th>
                    <th>DD CODE</th>
                    <th>Amount</th>
                    <th>Receipt Number</th>
                </tr>
                {tableContent}
                </>
                }
            </table>
            </>
        )
    }
}

export default StatementTable